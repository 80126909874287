import React, { ReactNode } from 'react';

import { COLOR_MODE_KEY, COLORS, INITIAL_COLOR_MODE_CSS_PROP } from '../colors';

export enum ColorMode {
  dark = 'dark',
  light = 'light',
}

export const ThemeContext = React.createContext<{ colorMode?: ColorMode; setColorMode: (value: ColorMode) => void }>({
  colorMode: undefined,
  setColorMode: (colorMode: ColorMode) => {},
});

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const [colorMode, rawSetColorMode] = React.useState(ColorMode.light);

  React.useEffect(() => {
    const rootStyle = window.document.documentElement.style;
    rawSetColorMode(rootStyle.getPropertyValue(INITIAL_COLOR_MODE_CSS_PROP) as ColorMode);
  }, []);

  const contextValue = React.useMemo(() => {
    function setColorMode(colorMode: ColorMode) {
      const root = window.document.documentElement;

      localStorage.setItem(COLOR_MODE_KEY, colorMode);

      Object.entries(COLORS).forEach(([name, colorByTheme]) => {
        const cssVarName = `--color-${name}`;

        root.style.setProperty(cssVarName, colorByTheme[colorMode]);
      });

      rawSetColorMode(colorMode);
    }

    return {
      colorMode,
      setColorMode,
    };
  }, [colorMode, rawSetColorMode]);

  return <ThemeContext.Provider value={contextValue}>{children}</ThemeContext.Provider>;
};
