import React, { ReactNode } from 'react';

import './app.css';
import { ThemeProvider } from './ThemeContext';

function App({ children }: { children: ReactNode }) {
  return <ThemeProvider>{children}</ThemeProvider>;
}

export default App;
