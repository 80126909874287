export const COLORS = {
  text: {
    light: '#1a1a1a',
    dark: '#ededed',
  },
  background: {
    light: '#FAFAFA',
    dark: '#262e3f',
  },
  primary: {
    light: '#e29d15',
    dark: '#fbb11e',
  },
  primary10: {
    light: '#eaab2e',
    dark: '#ffc041',
  },
  // Grays, scaling from least-noticeable to most-noticeable
  gray300: {
    light: 'hsl(0deg, 0%, 70%)',
    dark: 'hsl(0deg, 0%, 30%)',
  },
  gray500: {
    light: 'hsl(0deg, 0%, 50%)',
    dark: 'hsl(0deg, 0%, 50%)',
  },
  gray700: {
    light: 'hsl(0deg, 0%, 30%)',
    dark: 'hsl(0deg, 0%, 70%)',
  },
};

export const COLOR_MODE_KEY = 'color-mode';
export const INITIAL_COLOR_MODE_CSS_PROP = '--initial-color-mode';
